import {http} from '@/utils/http'

/**
 * 重置密码
 * @returns {Promise}
 * @param data
 */
export const passwordReset = (data: { password: string; token: string | undefined }) => {
    return http.post({
        url: '/account/forget/pwd',
        data,
    })
}

