import {RouteRecordRaw, createRouter, createWebHashHistory,} from 'vue-router'

import Download from '../views/download/DownloadApp.vue'
import ResetPassword from '../views/rest_password/RestPassword.vue'
import BindBankCardRule from '../views/bind_bank_card_rule/BindBankCardRule.vue'
import BindPayPalRule from "@/views/bind_paypal_rule/BindPayPalRule.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        redirect: '/download_app',
    },
    {
        path: '/download_app',
        name: 'downloadApp',
        component: Download,
    },
    {
        path: '/reset_password',
        name: 'resetPassword',
        component: ResetPassword,
    },
    {
        path: '/bind_bank_card_rule',
        name: 'bindBankCardRule',
        component: BindBankCardRule,
    },{
        path: '/bind_paypal_rule',
        name: 'bindPaypalRule',
        component: BindPayPalRule,
    },
]


const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title != null && typeof to.meta.title == 'string') {
        document.title = to.meta.title as string
    }
    next();
});

export default router
