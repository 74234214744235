import {stringify} from 'qs'
import {CreateAxios, CreateAxiosOptions} from './createAxios'
import {Toast} from 'vant'
import {ErrorCode} from "@/proto/meta";

const options: CreateAxiosOptions = {
    // 携带token、get请求params数组处理
    interceptorsRequestSuccessFunc: (config) => {
        const headers: any = {}
        return {
            ...config,
            headers,
            //形式： ids=1&ids=2&id=3
            paramsSerializer: (params) => stringify(params, {arrayFormat: 'repeat'}),
        }
    },
    /**
     * 业务代码处理
     * @param response
     */
    responseHandleFunc: async (response) => {
        const {data} = response
        let message = ''
        switch (data.code) {
            case ErrorCode.SUCCESS:
                return data.data
            default:
                message = data.message
                break
        }
        if (message) {
            Toast.fail({message: message})
        }

        if (data.code !== ErrorCode.SUCCESS) {
            throw new Error(JSON.stringify(data))
        }
    },
    /**
     * 请求状态码处理
     */
    errorHandleFunc: (async (error: any) => {
        console.log(error)
        const {status, code} = error
        let message = error.message
        // 处理请求超时
        if (!status && code === 'ECONNABORTED') {
            message = 'timeout error'
        }

        if (message) {
            Toast.fail({message: message})
        }
    }),

    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 8000,
}

export const http = new CreateAxios(options)
