import { createStore } from 'vuex'

// eslint-disable-next-line import/no-default-export
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
})
