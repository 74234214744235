/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.21.8
 * source: meta.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
export enum AccountState {
    INITIAL = 0,
    NORMAL = 1,
    DISABLE = 2,
    DEACTIVATE = 3,
    INVALID = 100
}
export enum AccountAuthType {
    EMAIL = 0,
    PHONE = 1,
    APPLE = 2
}
export enum AccountVerifyType {
    VERIFY_EMAIL = 0,
    VERIFY_PHONE = 1,
    VERIFY_PERSON = 2
}
export enum ReportType {
    REPORT_USER = 0
}
export enum RelationStatisticType {
    USER_EXPOSURE = 0
}
export enum CardNumberChangeType {
    OPERATE_CARD_DEC = 0,
    REFRESH_CARD_INC = 1,
    WATCH_AD_INC = 2,
    BECOME_VIP_INC = 3,
    ACTIVATE_NEW_USER_INC = 4
}
export enum MatchOperateType {
    LIKE = 0,
    PASS = 1
}
export enum ReportUserReasonType {
    INAPPROPRIATE_PROFILE = 0,
    INAPPROPRIATE_MESSAGE = 1,
    SCAM = 2,
    SUGAR_BABY_OR_DADDY = 3,
    FAKE_PROFILE = 4,
    OTHER = 5
}
export enum RelationShip {
    RELATIONSHIP_LIKE = 0,
    RELATIONSHIP_PASS = 1,
    RELATIONSHIP_MATCH = 2,
    RELATIONSHIP_UN_MATCH = 3,
    RELATIONSHIP_BLOCK = 4
}
export enum Gender {
    FEMALE = 0,
    MALE = 1,
    ANY = 2
}
export enum PrivilegeState {
    NONE = 0,
    ACTIVATE = 1,
    EXPIRED = 2
}
export enum CanRecommendCard {
    FALSE = 0,
    TRUE = 1
}
export enum PushInterval {
    IMMEDIATELY = 0,
    TWICE_DAILY = 1,
    THREE_DAYS = 2,
    OFF = 3
}
export enum Platform {
    IOS = 0,
    ANDROID = 1
}
export enum PushType {
    PUSH_NEW_LIKE = 0,
    PUSH_NEW_MATCH = 1,
    PUSH_NEW_MESSAGE = 2,
    PUSH_NEW_CARD = 3,
    PUSH_NEW_VISITOR = 4,
    PUSH_NEW_NOTE = 5,
    PUSH_TRUE_PHOTO_EXPIRE = 6
}
export enum MessageType {
    MESSAGETYPE_TEXT = 0,
    MESSAGETYPE_PIC = 1,
    MESSAGETYPE_AUDIO = 2,
    MESSAGETYPE_TRUE_PHOTO_EXPIRE = 3,
    MESSAGETYPE_RTM = 4
}
export enum NoteType {
    NOTETYPE_TEXT = 0,
    NOTETYPE_PIC = 1
}
export enum NoteState {
    NOTESTATE_NORMAL = 0,
    NOTESTATE_DELETE = 1
}
export enum MQTTMessageType {
    NEW_MESSAGE = 0,
    NEW_MATCH = 1,
    NEW_VISITOR = 2,
    NEW_LIKEU = 3,
    ACCOUNT_DISABLE = 4,
    NEW_NOTE = 5
}
export enum AccountSettingType {
    SOUND = 0,
    VIBRATE = 1,
    HIDE_MATCH = 2,
    ANONYMOUS_VISITORS = 3,
    DO_NOT_DISTURB = 4,
    EMAIL_UNREAD_MESSAGES = 5,
    EMAIL_PROMOTIONS = 6
}
export enum SettingSwitch {
    SWITCH_OFF = 0,
    SWITCH_ON = 1
}
export enum GooglePayPurchaseState {
    DONE = 0,
    CANCEL = 1,
    PENDING = 2
}
export enum VisitorReadState {
    VISITOR_READ = 0,
    VISITOR_UNREAD = 1
}
export enum PrivilegeType {
    CONSUMABLE = 0,
    RENEWABLE = 1
}
export enum IAPActionType {
    CLIENT = 0,
    SERVER_TO_SERVER = 1,
    SERVER_CRONTAB = 2
}
export enum GoodsAvailableState {
    UNAVAILABLE = 0,
    AVAILABLE = 1
}
export enum RTMType {
    RTMType_Audio = 0,
    RTMType_Video = 1
}
export enum CoinOPType {
    AUDIO_ADD = 0,
    AUDIO_MINUS = 1,
    VIDEO_ADD = 2,
    VIDEO_MINUS = 3,
    PURCHASE = 4,
    WITHDRAW = 5,
    DRAW_BACK = 6
}
export enum GoodsGroup {
    VIP = 0,
    BOOST = 1,
    BOOST_SALE = 2,
    COIN = 3
}
export enum CoinLevelTableType {
    COIN_LEVEL_PRICE = 0,
    COIN_LEVEL_RULE = 1
}
export enum ConsumedGoodsSubtype {
    SUBTYPE_BOOST = 0,
    SUBTYPE_COIN = 1
}
export enum ServerToServerStatus {
    SERVER_TO_SERVER_OK = 0
}
export enum ReviewType {
    REVIEW_AVATAR = 0,
    REVIEW_BETRUE = 1
}
export enum ReviewOpType {
    REVIEW_ACCEPT = 0,
    REVIEW_REJECT = 1
}
export enum OssOpLogTypeEnum {
    OSS_OP_LOG_TYPE_LOGIN = 0,
    OSS_OP_LOG_TYPE_LOGOUT = 1,
    OSS_OP_LOG_TYPE_REVIEW_AVATAR = 2,
    OSS_OP_LOG_CHANGE_ACCOUNT_STATE = 3,
    OSS_OP_LOG_REPORT_RESOLVED = 4,
    OSS_OP_LOG_CHANGE_VIP_STATE = 5,
    OSS_OP_LOG_ADD_AD = 6,
    OSS_OP_LOG_UPDATE_AD = 7,
    OSS_OP_LOG_UPDATE_AD_INC_MARCH_NUM = 8,
    OSS_OP_LOG_INQUIRE_UPDATE_PROFILE = 9,
    OSS_OP_LOG_SET_PROFILE = 10,
    OSS_OP_LOG_TYPE_REVIEW_BETRUE = 11,
    OSS_OP_LOG_CHANGE_USER_PASSWORD = 12
}
export enum MatchRelatedLogType {
    SEND_MATCH_CARD = 0,
    OP_MATCH_CARD = 1,
    SET_MATCHED = 2,
    OP_LIKE_U = 3
}
export enum OssChangeVIPState {
    OSS_CHANGE_VIP_CANCEL = 0,
    OSS_CHANGE_VIP_ADD = 1
}
export enum ImageType {
    IMAGE_MAIN_AVATAR = 0,
    IMAGE_OTHER_AVATAR = 1,
    IMAGE_FLOW = 2
}
export enum ADType {
    AD_APP_OPEN = 0,
    AD_BANNER = 1,
    AD_INTERSTITIAL = 2,
    AD_REWARDED = 3
}
export enum ADPosition {
    AD_POSITION_APP_OPEN = 0,
    AD_POSITION_MORE = 1,
    AD_POSITION_VISITORS = 2,
    AD_POSITION_UNLOCK_LIKE_U = 3,
    AD_POSITION_ADD_CARDS = 4,
    ADD_POSITION_UNLOCK_VISITOR = 5
}
export enum ADState {
    AD_OFF = 0,
    AD_ON = 1
}
export enum ImageFlowState {
    IMAGE_FLOW_NORMAL = 0,
    IMAGE_FLOW_DELETE = 1
}
export enum ImageFlowLikeState {
    IMAGE_FLOW_LIKE_NORMAL = 0,
    IMAGE_FLOW_LIKE_DELETE = 1
}
export enum SystemConfigType {
    SystemConfigType_InReviewVersion = 0,
    SystemConfigType_AdIncMatchNum = 1,
    SystemConfigType_ExcludeCity = 2,
    SystemConfigType_Disable_Recommend_City = 3
}
export enum FootprintType {
    FOOTPRINT_TYPE_LOCATION_CHANGE = 0,
    FOOTPRINT_TYPE_PHOTO_ALBUM = 1,
    FOOTPRINT_TYPE_USER_CREATE = 2
}
export enum FootprintState {
    FOOTPRINT_STATE_NORMAL = 0,
    FOOTPRINT_STATE_DELETE = 1
}
export enum ActivateOPType {
    ActivateOPType_NEW_USER_ADD_EXTRA_CARD = 0
}
export enum BoostActivationState {
    BOOST_ACTIVATION_INACTIVE = 0,
    BOOST_ACTIVATION_ACTIVATED = 1,
    BOOST_ACTIVATION_EXPIRED = 2
}
export enum VoiceCallState {
    TURN_OFF_VOICE_CALL = 0,
    TURN_ON_VOICE_CALL = 1
}
export enum VideoCallState {
    TURN_OFF_VIDEO_CALL = 0,
    TURN_ON_VIDEO_CALL = 1
}
export enum PayoutTransactionState {
    PAYOUT_SUCCESS = 0,
    PAYOUT_FAILED = 1,
    PAYOUT_PENDING = 2,
    PAYOUT_UNCLAIMED = 3,
    PAYOUT_RETURNED = 4,
    PAYOUT_ONHOLD = 5,
    PAYOUT_BLOCKED = 6,
    PAYOUT_REFUNDED = 7,
    PAYOUT_REVERSED = 8
}
export enum ReceiptChannel {
    PAYPAL_BALANCE = 0
}
export enum EventStatisticType {
    DAILY_LOGIN_USER_COUNT = 0,
    DAILY_TAB_MY_CITIES_COUNT = 1,
    DAILY_TAB_MOMENTS_COUNT = 2,
    DAILY_TAB_BETRUE_COUNT = 3,
    DAILY_TAB_LIKES_COUNT = 4,
    DAILY_TAB_PROFILE_COUNT = 5,
    DAILY_TAB_MESSAGE_COUNT = 6,
    DAILY_BUY_BADGE_COUNT = 7,
    DAILY_BUY_MORE_CARD_COUNT = 8,
    DAILY_BUY_VISITORS_COUNT = 9,
    DAILY_BUY_LIKE_U_COUNT = 10,
    DAILY_BUY_LOCATION_COUNT = 11,
    DAILY_BUY_NOTE_COUNT = 12,
    DAILY_BUY_SAME_CITY_USER_COUNT = 13,
    DAILY_EVERY_USER_CONSUME_CARD_COUNT = 14,
    DAILY_SUCCESS_MATCH_COUNT = 15,
    DAILY_NEW_USER_COUNT = 16,
    DAILY_BUY_VIP_ENTRY_BADGE = 17,
    DAILY_BUY_VIP_ENTRY_MORE_CARD = 18,
    DAILY_BUY_VIP_ENTRY_VISITORS = 19,
    DAILY_BUY_VIP_ENTRY_LIKE_U = 20,
    DAILY_BUY_VIP_ENTRY_LOCATION = 21,
    DAILY_BUY_VIP_ENTRY_NOTE = 22,
    DAILY_BUY_VIP_ENTRY_SAME_CITY_USER = 23,
    EVENT_CLICK_REMIND_UPDATE_TRUE_PHOTO = 24
}
export enum ErrorCode {
    SUCCESS = 0,
    FAIL = 1,
    UNKNOWN_ERROR = 2,
    PARAMS_ERROR = 3,
    NO_ACCOUNT = 4,
    NOT_LOGIN = 5,
    NOT_FOUND_IN_DB = 6,
    INTERNAL_ERROR = 7,
    NO_TOKEN = 8,
    TOKEN_INVALID = 9,
    TOKEN_REDIS_NOT_FOUND = 10,
    TOKEN_EXPIRED = 11,
    USER_EXIST = 12,
    USER_NOT_EXIST = 13,
    ACCOUNT_DELETED = 14,
    ACCOUNT_EXIST_CAN_LOGIN = 15,
    USER_HAS_BEEN_BANNED = 16,
    SEND_MAIL_ERROR = 17,
    CODE_EXPIRED = 18,
    CODE_ERROR = 19,
    CODE_EXISTED = 20,
    USER_ACCOUNT_EXCEPTION = 21,
    DB_ERROR = 22,
    ENUM_NOT_DEFINED = 23,
    EMAIL_HAS_BEEN_USED = 24,
    ACCOUNT_DISABLED = 25,
    REPETITIVE_OPERATION = 26,
    PASSWORD_ERROR = 27,
    USER_DEVICE_NOT_EXISTS = 28,
    REDIS_ERROR = 29,
    OPERATION_DENIED = 30,
    USER_SETTING_NOT_EXISTS = 31,
    NOT_HAVE_INACTIVE_BOOST = 32,
    HTTP_REQUEST_ERROR = 33,
    VERIFY_INFO_NON_EXISTENT = 34,
    PROFILE_SCENE_UNEXIST = 10001,
    USER_PROFILE_UNEXIST = 10002,
    USER_PAYPAL_ID_UNEXIST = 10003,
    DB_GET_MATCH_POOL_FAIL = 20001,
    DB_REFRESH_MATCH_CARD_FAIL = 20002,
    DB_INSERT_MATCH_CARD_FAIL = 20003,
    REPEAT_MATCH_CARD_OPERATION = 20004,
    CANNOT_MATCH_OWN = 20005,
    CANNOT_REDUCE_CARD = 20006,
    CANNOT_ADD_MATCH_CARD = 20007,
    CANNOT_UNMATCH_CUSTOMER = 20008,
    DB_UPDATE_RELATIONSHIP_FAIL = 21001,
    DB_INSERT_RELATIONSHIP_FAIL = 21002,
    DB_INSERT_STATISTIC_FAIL = 21003,
    SELECT_AREAS_ONLY_BUT_NO_AREAS = 21004,
    DB_UPDATE_FRIEND_LIST_FAIL = 22001,
    DB_INSERT_FRIEND_LIST_FAIL = 22002,
    DB_INSERT_MATCH_SHARE_FAIL = 23001,
    DB_UPDATE_MATCH_SHARE_FAIL = 23002,
    SHARE_CARD_TOKEN_EXPIRED = 23003,
    SHARERS_CANNOT_ADD = 23004,
    CANNOT_SHARE_OWN = 23005,
    OSS_NO_PENDING_REVIEW = 24001,
    OSS_REVIEW_VALUE_INCONFORMITY = 24002,
    APPLE_RECEIPT_EMPTY = 30001,
    APPLE_IN_APP_EMPTY = 30002,
    TRANSACTION_NOT_IN_APP = 30003,
    APPLE_RECEIPT_ENV_ERROR = 30004,
    GOOGLE_PAY_VERIFY_EMPTY = 30005,
    GOOGLE_PAY_PURCHASE_STATE_CANCEL = 30006,
    GOOGLE_PAY_PURCHASE_STATE_PENDING = 30007,
    GOOGLE_PAY_VERIFY_ERROR = 30008,
    APPLE_RECEIPT_ERROR = 30009,
    APPLE_SERVER_TO_SERVER_ERROR = 30010,
    APPLE_SERVER_TO_SERVER_JWS_VERIFY_ERROR = 30011,
    GOOGLE_PUBLISHER_CREATE_ERROR = 30012,
    APPLE_IAP_PURCHASE_ERROR = 30013,
    CONSUMPTION_TRANSACTION_ADD_REPEAT = 30014,
    GET_PAYPAL_PAYOUT_BATCH_ERROR = 30015,
    MESSAGE_FRIEND_UNMATCH = 40001,
    MESSAGE_FRIEND_DEACTIVE = 40002,
    MESSAGE_FRIEND_DISABLE = 40003,
    MESSAGE_FRIEND_BLOCK = 40004,
    MESSAGE_CANNOT_SEND_TO_CUSTOMER_SERVICE = 40005,
    NOTE_ACCOUNT_DEACTIVE = 40006,
    NOTE_ACCOUNT_DISABLE = 40007,
    NOTE_ACCOUNT_BLOCK = 40008,
    NOTE_CANNOT_SEND_TO_CUSTOMER_SERVICE = 40009,
    IMAGE_FLOW_NOT_EXISTS = 50001,
    IMAGE_FLOW_INSERT_FAIL = 50002,
    IMAGE_FLOW_DELETE_FAIL = 50003,
    IMAGE_FLOW_UIN_INSERT_FAIL = 50004,
    IMAGE_FLOW_LIKE_FAIL = 50005,
    IMAGE_FLOW_UNLIKE_FAIL = 50006,
    IMAGE_CANNOT_SEND_TO_CUSTOMER_SERVICE = 50007,
    DB_SYSTEM_CONFIG_FAIL = 60001,
    COIN_NOT_ENOUGH = 70001,
    RTM_TYPE_INVALID = 70002
}
export class SignupRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        email?: string;
        password?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("email" in data && data.email != undefined) {
                this.email = data.email;
            }
            if ("password" in data && data.password != undefined) {
                this.password = data.password;
            }
        }
    }
    get email() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set email(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get password() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set password(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        email?: string;
        password?: string;
    }): SignupRequest {
        const message = new SignupRequest({});
        if (data.email != null) {
            message.email = data.email;
        }
        if (data.password != null) {
            message.password = data.password;
        }
        return message;
    }
    toObject() {
        const data: {
            email?: string;
            password?: string;
        } = {};
        if (this.email != null) {
            data.email = this.email;
        }
        if (this.password != null) {
            data.password = this.password;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.email.length)
            writer.writeString(1, this.email);
        if (this.password.length)
            writer.writeString(2, this.password);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SignupRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SignupRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.email = reader.readString();
                    break;
                case 2:
                    message.password = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): SignupRequest {
        return SignupRequest.deserialize(bytes);
    }
}
export class SignupBO extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        uin?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("uin" in data && data.uin != undefined) {
                this.uin = data.uin;
            }
        }
    }
    get uin() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set uin(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        uin?: string;
    }): SignupBO {
        const message = new SignupBO({});
        if (data.uin != null) {
            message.uin = data.uin;
        }
        return message;
    }
    toObject() {
        const data: {
            uin?: string;
        } = {};
        if (this.uin != null) {
            data.uin = this.uin;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.uin.length)
            writer.writeString(1, this.uin);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SignupBO {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SignupBO();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.uin = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): SignupBO {
        return SignupBO.deserialize(bytes);
    }
}
