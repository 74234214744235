/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
function getMobileOperatingSystem() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone'
  }

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }

  return 'unknown'
}

export function openNativeApp() {
  let deeplink
  const platform = getMobileOperatingSystem()
  if (platform === 'Android') {
    deeplink = `meetaapp://${process.env.VUE_APP_APPLINKS_DOMAIN}${process.env.VUE_APP_DEEPLINK_PREFIXPATH}`
  }
  else {
    deeplink = `https://applinks.${process.env.VUE_APP_APPLINKS_DOMAIN}${process.env.VUE_APP_DEEPLINK_PREFIXPATH}`
  }
  const loadDateTime = new Date()
  // 设置时间阈值，在规定时间里面没有打开对应App的话，直接去App store进行下载。
  window.setTimeout(function() {
    const timeOutDateTime = new Date()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if ((timeOutDateTime - loadDateTime) < 2200) {
      openAppStore()  // APP下载地址
    } else {
      window.close()
    }
  },2000)
  window.location.replace(deeplink)
}

export function openAppStore() {
  let downloadUrl = ''

  const platform = getMobileOperatingSystem()
  // todo 需要更改app地址
  if (platform === 'iOS') {
    // do something in iOS
    downloadUrl = 'https://apps.apple.com/app/prayrooms/id1612419544'
  }
  else {
    downloadUrl = 'https://play.google.com/store/apps/details?id=com.befruitfullimited.prayrooms'
  }

  window.location.replace(downloadUrl)
}